const development = {
  apiGateway: {
    BASE_URL: "https://api-uat.acrocalypse.gg",
  },
};

const uat = {
  apiGateway: {
    BASE_URL: "https://api-uat.acrocalypse.gg",
  },
};

const production = {
  apiGateway: {
    BASE_URL: "https://api.acrocalypse.gg",
  },
};

let baseConfig =
  process.env.REACT_APP_STAGE === "production"
    ? production
    : process.env.REACT_APP_STAGE === "uat"
    ? uat
    : development;

const AppConfig = {
  REACT_APP_STAGE: process.env.REACT_APP_STAGE,
  // REACT_APP_ALCHEMY_RINKEBY_KEY: process.env.REACT_APP_ALCHEMY_RINKEBY_KEY,
  // REACT_APP_ALCHEMY_MAINNET_KEY: process.env.REACT_APP_ALCHEMY_MAINNET_KEY,
  REACT_APP_ALCHEMY_RINKEBY_KEY: "",
  REACT_APP_ALCHEMY_MAINNET_KEY: "",
  REACT_APP_ETHERS_NETWORK: process.env.REACT_APP_ETHERS_NETWORK,
  GA_TRACKING_ID: "G-ZFSR55EP0T",
  BUY_ON_OS_URL: "https://opensea.io/collection/acrocalypse",
  REACT_APP_VERSION: 1,
  CURRENT_WHITELIST_PHASE: 2,
  ...baseConfig,
};

export default AppConfig;
