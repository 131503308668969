import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  NavLink,
} from "reactstrap";

function BuyDropdown() {
  return (
    <>
      <UncontrolledDropdown className="buy-dropdown-holder">
        <DropdownToggle
          className="btn-mint-a-croc cursor-pointer btn-transparent"
          caret
        >
          Buy
        </DropdownToggle>
        <DropdownMenu className="buy-dropdown">
          <ul className="buy-dropdown-wrap">
            <li>
              <NavLink
                href="https://opensea.io/collection/acrocalypse"
                target="_blank"
              >
                CROCS
              </NavLink>
            </li>
            <li>
              <NavLink
                href="https://opensea.io/collection/acrocspacecraft"
                target="_blank"
              >
                CRAFT
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                href="https://looksrare.org/collections/0xd73acd7f5099fdd910215dbff029185f21ffbcf0"
                target="_blank"
              >
                (LR) CROCS
              </NavLink>
            </li>
            <li>
              <NavLink
                href="https://looksrare.org/collections/0x568a6f14c8096983b341b5514930a45637e13222"
                target="_blank"
              >
                (LR) CRAFT
              </NavLink>
            </li> */}
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default BuyDropdown;
