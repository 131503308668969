import React from "react";
import { Container, Row, Col } from "reactstrap";
// import AppConfig from '../config/config'

// Static Assets
import About01 from "../assets/images/About-01.gif";
// import About02 from '../assets/images/About-02.gif';
import DigitalUtility from "../assets/images/DigitalUtility.gif";
import FutureMints from "../assets/images/FutureMints.gif";
import Invasions from "../assets/images/Invasions.gif";
import Papers from "../assets/images/Papers.gif";
import ControlRoom from "../assets/images/control_room.gif";

// acroclypse Images Import
// import Image1 from "../assets/images/loop-images/control_room_nocroc.gif";
// import Image2 from "../assets/images/loop-images/control_room_1.gif";
// import Image3 from "../assets/images/loop-images/control_room_2.gif";
// import Image4 from "../assets/images/loop-images/control_room_3.gif";
// import Image5 from "../assets/images/loop-images/control_room_4.gif";
// import Image6 from "../assets/images/loop-images/control_room_5.gif";

// Static UI Imports
import BuyDropdown from "../layout/BuyDropdown";

// const imageArray = [Image2, Image3, Image4, Image5, Image6];

interface SectionSectionAboutProps {}

interface SectionSectionAboutState {
  imageCount: number;
}

class SectionSectionAbout extends React.Component<
  SectionSectionAboutProps,
  SectionSectionAboutState
> {
  constructor(props: SectionSectionAboutProps) {
    super(props);
    this.state = {
      imageCount: 0,
    };
  }

  // updateImageCount = async () => {
  //   clearInterval(this.state.intervalId);
  //   let imageCount = this.state.imageCount + 1;
  //   if (imageCount === imageArray.length) {
  //     imageCount = 0;
  //   }

  //   this.setState({
  //     imageCount,
  //   });
  // };

  componentDidMount() {
    // setInterval(() => {
    //   this.updateImageCount();
    // }, 2000);
  }

  render() {
    return (
      <>
        <section id="About" className="section-about">
          <Container fluid={false}>
            <Row className="d-flex align-items-center justify-content-between odd">
              <Col xs={12} md={6} lg={6} className="order-md-1">
                <div className="section-adjust">
                  <h1 className="title">PREPARE FOR CROC TAKEOVER</h1>
                  <p className="fs-24">
                    ACROCALYPSE IS AN NFT COLLECTIBLE FEATURING 10,420 SPACE
                    RACING CROCS TURNED WARRIOR. ASSEMBLE YOUR CROC(S) BY OWNING
                    ITS NFT ON THE ETHEREUM BLOCKCHAIN. DOING SO WILL GRANT YOU
                    A SPOT IN THE GALAXY'S FIRST LINE OF DEFENCE. 10K NFTs,
                    OFFICIALLY SOLD OUT.
                  </p>
                  <div className="section-buttons d-flex align-items-center">
                    <a
                      href="https://discord.gg/7KZQNFMJrE"
                      className="btn-mint-a-croc lg mr-2"
                    >
                      Join Discord
                    </a>

                    {/* <a
                      href={AppConfig.BUY_ON_OS_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="btn-mint-a-croc lg cursor-pointer"
                    >
                      BUY ON OS
                    </a> */}

                    <BuyDropdown />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={6} className="order-md-2">
                <div className="graphic-adjust">
                  <img
                    src={ControlRoom}
                    alt="WHAT IS ACROCALYPSE?"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>

            <Row className="d-flex align-items-center justify-content-between even">
              <Col xs={12} md={6} lg={6} className="order-md-2">
                <div className="section-adjust">
                  <h1 className="title">WHAT IS ACROCALYPSE?</h1>
                  <p className="fs-24">
                    ACROCALYPSE IS OUR MOST AMBITIOUS MISSION YET, THE BEGINNING
                    OF A NEXT-GENERATION ECOSYSTEM FOR OUR COMMUNITY OF GALACTIC
                    CROCS. OUR GOAL IS TO CONTINUE TO RAPIDLY BUILD THE PREMIER
                    PLATFORMS, APPS AND MARKETPLACE NEEDED FOR $PAPER TO
                    TAKEOVER. WE ARE A COMMUNITY DRIVEN COLLECTION OF 10K
                    QUALITY-FOCUSED, PIXEL AVATARS POWERED BY UNRIVALED DIGITAL
                    UTILITY. JOIN THE CROCS, FREN.
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6} lg={6} className="order-md-1">
                <div className="graphic-adjust pull-right">
                  <img
                    src={About01}
                    alt="PREPARE FOR CROC TAKEOVER"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>

            <Row className="d-flex align-items-center justify-content-between text-center mb-0">
              <Col xs={12} md={12} lg={12}>
                <h2>WHY JOIN ACROCALYPSE?</h2>
                <ul className="acrocalypse-lists">
                  <li className="acrocalypse-lists-item">
                    <img
                      src={DigitalUtility}
                      className="img-fluid"
                      alt="Digital Utility"
                    />
                    <h5 className="acrocalypse-subtitle">Digital Utility</h5>
                  </li>
                  <li className="acrocalypse-lists-item">
                    <img
                      src={FutureMints}
                      className="img-fluid"
                      alt="Future Mints"
                    />
                    <h5 className="acrocalypse-subtitle">Future Mints</h5>
                  </li>
                  <li className="acrocalypse-lists-item">
                    <img
                      src={Invasions}
                      className="img-fluid"
                      alt="Invasions"
                    />
                    <h5 className="acrocalypse-subtitle">Invasions</h5>
                  </li>
                  <li className="acrocalypse-lists-item">
                    <img src={Papers} className="img-fluid" alt="$paper" />
                    <h5 className="acrocalypse-subtitle">$paper</h5>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionSectionAbout;
