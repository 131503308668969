import { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavbarToggler, Collapse } from "reactstrap";
import { Link } from "react-scroll";

// Static UI imports
import Menu from "./Menu";
import BuyDropdown from "../layout/BuyDropdown";

// Static Assets
import LogoIcon from "../assets/images/logo-icon@2x.png";
// import AppConfig from '../config/config'

function Header() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  useEffect(() => {
    const header = document.getElementById("appHeader");
    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("is-sticky");
        } else {
          header.classList.remove("is-sticky");
        }
      });
    }
  });

  return (
    <>
      <header className="header-horizontal">
        <Container className="">
          <div className="d-flex align-items-center justify-content-between">
            <Link
              to="Home"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className="cursor-pointer"
            >
              <img src={LogoIcon} className="header-logo" alt="Acrocalypse" />
            </Link>

            <Nav className="header-menu header-menu-sm">
              <Menu
                setCollapsed={() => {}}
                collapsed={collapsed}
                className="header-menu-item"
              />
            </Nav>

            <Navbar
              color="faded"
              light
              className={`${collapsed ? `navigation-active` : ``}`}
            >
              <NavbarToggler
                className=""
                onClick={() => setCollapsed(!collapsed)}
              />
              <Collapse className="responsive-navigation" isOpen={collapsed}>
                <div
                  className="close-menu"
                  style={{ display: "flex" }}
                  data-uw-styling-context="true"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <span
                    className="icon-close"
                    data-uw-styling-context="true"
                  ></span>
                </div>
                <Menu setCollapsed={setCollapsed} collapsed={collapsed} />
              </Collapse>
              {collapsed && (
                <div
                  className="menu-overlay"
                  onClick={() => setCollapsed(!collapsed)}
                ></div>
              )}
            </Navbar>

            {/* <a
              href={AppConfig.BUY_ON_OS_URL}
              target="_blank"
              rel="noreferrer"
              className="btn-mint-a-croc cursor-pointer"
            >
              BUY ON OS
            </a> */}

            <BuyDropdown />
          </div>
        </Container>
      </header>
    </>
  );
}

export default Header;
