import React from 'react'
// import Ticker from "react-ticker";

interface SectionPresaleProps {}
interface SectionPresaleState {}

class SectionPresale extends React.Component<
  SectionPresaleProps,
  SectionPresaleState
> {
  constructor(props: SectionPresaleProps) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
        <div className="presale">
          <div className="presale-wrap">
            <div className="presale-text">
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE ||
            </div>
            <div className="presale-text">
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE ||
              ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0 NOW ONLINE || ROADMAP 2.0
              NOW ONLINE ||
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default SectionPresale
