import React from "react";
import { Container, Row, Col } from "reactstrap";

// Static Assets
import Logo from "../assets/images/newlogo@2x.png";
import PaperhandGG from "../assets/images/background/Paperhand-GG@2x.gif";
// import AppConfig from '../config/config'

// Static UI Imports
import BuyDropdown from "../layout/BuyDropdown";

interface SectionSectionHomeProps {}
interface SectionSectionHomeState {}

class SectionSectionHome extends React.Component<
  SectionSectionHomeProps,
  SectionSectionHomeState
> {
  constructor(props: SectionSectionHomeProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section id="Home" className="section-intro">
          <div className="section-intro-wrap">
            <a
              rel="noreferrer"
              href="https://www.paperhands.gg/"
              target="_blank"
              className="paperhand-logo"
            >
              <img src={PaperhandGG} className="img-fluid" alt="PaperhandGG" />
            </a>
            <div className="mx-auto">
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <img src={Logo} className="page-logo" alt="Acrocalypse" />
              </div>
            </div>
            <Container>
              <Row className="d-flex align-items-center justify-content-center">
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  {/* <button
                    type="button"
                    onClick={() =>
                      window.open(AppConfig.BUY_ON_OS_URL, "_blank")?.focus()
                    }
                    className="btn-mint-a-croc lg"
                  >
                    BUY ON OS
                  </button> */}

                  <BuyDropdown />

                  <ul className="social-links d-flex align-items-center justify-content-center">
                    <li className="social-links-items">
                      <a
                        href="https://twitter.com/acrocalypseNFT"
                        target="_blank"
                        className="twitter-white"
                        rel="noreferrer"
                      >
                        <span className="sr-only">Twitter</span>
                      </a>
                    </li>
                    <li className="social-links-items">
                      <a
                        href="https://discord.gg/7KZQNFMJrE"
                        className="discord-white"
                      >
                        <span className="sr-only">Discord</span>
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </>
    );
  }
}

export default SectionSectionHome;
