import { Nav, NavItem } from "reactstrap";
import { Link } from "react-scroll";

interface Props {
  collapsed?: boolean;
  setCollapsed: (val: boolean) => void;
  className?: string;
}

function Menu({ className, collapsed, setCollapsed }: Props) {
  return (
    <Nav
      navbar={className ? false : true}
      className={`${className ? className : ``}`}
    >
      <NavItem className="header-menu-item">
        <Link
          to="About"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          activeClass="is--active"
          className="header-menu-link"
          onClick={() => setCollapsed(!collapsed)}
        >
          ABOUT
        </Link>
      </NavItem>
      <NavItem className="header-menu-item">
        <Link
          to="Roadmap"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          activeClass="is--active"
          className="header-menu-link"
          onClick={() => setCollapsed(!collapsed)}
        >
          ROADMAP
        </Link>
      </NavItem>
      <NavItem className="header-menu-item">
        <Link
          to="Team"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          activeClass="is--active"
          className="header-menu-link"
          onClick={() => setCollapsed(!collapsed)}
        >
          TEAM
        </Link>
      </NavItem>
      <NavItem className="header-menu-item">
        <Link
          to="FAQ"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          activeClass="is--active"
          className="header-menu-link"
          onClick={() => setCollapsed(!collapsed)}
        >
          FAQ
        </Link>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://mint.acrocalypse.gg/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          MINT
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://console.acrocalypse.gg/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          CONSOLE
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://alpha.paperhands.gg/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          ALPHA
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://marketplace.acrocalypse.gg/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          MARKETPLACE
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://acrocalypse.gitbook.io/acrocalypse/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          WHITEPAPER
        </a>
      </NavItem>
    </Nav>
  );
}

export default Menu;
