import { ToastContainer } from 'react-toastify'
import ReactGA from 'react-ga4'

import appConfig from './config/config'

// Menu
import Header from './layout/header'
import SectionHome from './sections/SectionHome'
import SectionPresale from './sections/SectionPresale'
import SectionAbout from './sections/SectionAbout'
import SectionReadTheWhitepaper from './sections/SectionReadTheWhitepaper'
import SectionRoadmap from './sections/SectionRoadmap'
import SectionTeam from './sections/SectionTeam'
import SectionFAQs from './sections/SectionFAQs'
import Footer from './layout/footer'

function App() {
  // initialize Google Analytic;
  ReactGA.initialize(appConfig.GA_TRACKING_ID)
  ReactGA.send({ hitType: 'pageview', page: '/' })

  return (
    <div className="app-wrapper">
      <Header />
      <SectionHome />
      <SectionPresale />
      <SectionAbout />
      <SectionReadTheWhitepaper />
      <SectionRoadmap />
      <SectionTeam />
      <SectionFAQs />
      <Footer />

      <ToastContainer autoClose={false} />
    </div>
  )
}

export default App
