/* eslint-disable jsx-a11y/anchor-has-content */
import { Container, Row, Col } from "reactstrap";

// Static Assets
import Whitepaper from "../assets/images/whitepaper.gif";

export default function SectionReadTheWhitepaper() {
  return (
    <>
      <section id="JoinTheAcrocalypse" className="section-join">
        <Container fluid={false}>
          <Row className="d-flex align-items-center justify-content-center">
            <Col xs={12} md={12} lg={12} xl={6} className="order-md-1">
              <div className="section-join-title">
                <h1>
                  <span className="sr-only">Join The Acrocalypse</span>
                </h1>
              </div>
            </Col>
            <Col xs={12} md={12} lg={12} xl={6} className="order-md-2">
              <img src={Whitepaper} alt="Whitepaper" className="img-fluid" />
            </Col>
          </Row>
        </Container>
        <a
          href="https://acrocalypse.gitbook.io/acrocalypse/"
          target="_blank"
          className="read-whitepaper"
          rel="noreferrer"
        ></a>
      </section>
    </>
  );
}
