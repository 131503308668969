import React from "react";
import { Container, Row, Col } from "reactstrap";

// Static Assets
import Team01 from "../assets/images/team1@2x.png";
import Team02 from "../assets/images/team2@2x.png";
import Team03 from "../assets/images/team3@2x.png";
import Team04 from "../assets/images/team4@2x.png";
import Team05 from "../assets/images/team5@2x.png";

interface SectionTeamProps {}
interface SectionTeamState {
  showTeamMember: number;
}

class SectionTeam extends React.Component<SectionTeamProps, SectionTeamState> {
  constructor(props: SectionTeamProps) {
    super(props);
    this.state = {
      showTeamMember: 1,
    };
    this.changeSlide = this.changeSlide.bind(this);
  }

  changeSlide = (incrementCount: number) => {
    this.setState({
      showTeamMember: this.state.showTeamMember + incrementCount,
    });
  };

  render() {
    return (
      <>
        <section id="Team" className="section-team">
          <img
            src={Team02}
            className="img-fluid"
            style={{ width: 0, height: 0 }}
            alt="hidden"
          />
          <img
            src={Team03}
            className="img-fluid"
            style={{ width: 0, height: 0 }}
            alt="hidden"
          />
          <img
            src={Team04}
            className="img-fluid"
            style={{ width: 0, height: 0 }}
            alt="hidden"
          />
          <img
            src={Team05}
            className="img-fluid"
            style={{ width: 0, height: 0 }}
            alt="hidden"
          />
          <Container fluid={false}>
            <h1 className="title text-center mb-100">ACROCALYPSE TEAM</h1>
            {this.state.showTeamMember === 1 && (
              <Row className="d-flex align-items-center justify-content-between even">
                <Col xs={12} md={6} lg={6} className="order-md-2">
                  <div className="section-adjust">
                    <h1 className="title">
                      <span>SAM WITWICKY</span>
                      <a
                        href="https://www.paperhands.gg"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        PAPERHANDS.GG
                      </a>
                    </h1>
                    <p className="fs-24">
                      <span className="w-100 d-inline-block text-white">
                        NFT DEGEN. FOUNDER. DEV.
                      </span>{" "}
                      SAM WITWICKY IS THE CREATOR OF{" "}
                      <a
                        href="https://www.paperhands.gg"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        PAPERHANDS.GG
                      </a>
                      . WITH OVER 70K UNIQUE WALLETS CONNECTED IN THE LAST 70
                      DAYS, IT MAKES THE FUN, FREE APP LOVED BY ALL ONE OF THE
                      FASTEST GROWING DAPPS IN THE WORLD. HE'S BEEN BUSY WORKING
                      IN THE SPACE CONSULTING AND DEVELOPING FOR MULTIPLE VC
                      BACKED WEB3 VENTURES OVER THE PAST 12 MONTHS.
                    </p>
                    <div className="fs-36">
                      <a
                        href="https://twitter.com/iblamenfts"
                        className="twitter-color d-flex"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="twitter-icon d-flex mr-2"></span>
                        @IBLAMENFTS
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} className="order-md-1">
                  <div className="graphic-adjust pull-right">
                    <img
                      src={Team01}
                      alt="SAM WITWICKY PAPERHANDS.GG"
                      className="img-fluid"
                    />
                    <div className="team-username">SAM WITWICKY</div>
                    <div className="team-controls">
                      <button type="button" className="btn-left" disabled>
                        Prev
                      </button>
                      <button
                        type="button"
                        className="btn-right"
                        onClick={() => this.changeSlide(1)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {this.state.showTeamMember === 2 && (
              <Row className="d-flex align-items-center justify-content-between even">
                <Col xs={12} md={6} lg={6} className="order-md-2">
                  <div className="section-adjust">
                    <h1 className="title">
                      <span>MAD MAX</span>
                      {/* <a
                        href="https://www.paperhands.gg"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        PAPERHANDS.GG
                      </a> */}
                    </h1>
                    <p className="fs-24">
                      <span className="w-100 d-inline-block text-white">
                        DEV. NFT DIAMONDHANDS. EXPLORER.
                      </span>{" "}
                      MAD MAX IS A FULL STACK DEVELOPER WHO'S BEEN WORKING AS A
                      CONTRACTOR FOR THE PAST 10+ YEARS DEVELOPING CUSTOM ERP &
                      BI SOLUTIONS FOR MEDIUM TO LARGE ENTERPRISE. MAX HAS A
                      SPECIAL KNACK FOR WORKING WITH DATA AND NUMBERS WHICH
                      COMPLIMENTS THE PAPERHANDS ECOSYSTEM EXPANSION PLANS TO A
                      TEE!
                    </p>
                    {/* <div className="fs-36">
                      <a
                        href="https://twitter.com/iblamenfts"
                        className="twitter-color d-flex"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="twitter-icon d-flex mr-2"></span>
                        @IBLAMENFTS
                      </a>
                    </div> */}
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} className="order-md-1">
                  <div className="graphic-adjust pull-right">
                    <img src={Team02} alt="MAD MAX" className="img-fluid" />
                    <div className="team-username">MAD MAX</div>
                    <div className="team-controls">
                      <button
                        type="button"
                        className="btn-left"
                        onClick={() => this.changeSlide(-1)}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        className="btn-right"
                        onClick={() => this.changeSlide(1)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {this.state.showTeamMember === 3 && (
              <Row className="d-flex align-items-center justify-content-between even">
                <Col xs={12} md={6} lg={6} className="order-md-2">
                  <div className="section-adjust">
                    <h1 className="title">
                      <span>PETER PARKER</span>
                      {/* <a
                        href="https://www.paperhands.gg"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        PAPERHANDS.GG
                      </a> */}
                    </h1>
                    <p className="fs-24">
                      <span className="w-100 d-inline-block text-white">
                        DEV. FRONT END GURU. CREATOR.
                      </span>{" "}
                      PETER IS THE NEWEST MEMBER TO JOIN THE DEV TEAM AND BRINGS
                      A WEALTH OF EXPERIENCE PREVIOUSLY WORKING IN TRADITIONAL
                      WEB DEV FOR THE PAST 5+ YEARS. PETER WILL BE PRIMARILY
                      FOCUSING ON FRONT END DEV FOR THE TIME BEING AND WILL BE
                      GRADUALLY GETTING INVOLVED WITH BACK END DEV IN DUE
                      COURSE. HASN'T MASTERED THE ART OF FULL DEGEN JUST YET,
                      BUT WE'RE WORKING ON IT.
                    </p>
                    {/* <div className="fs-36">
                      <a
                        href="https://twitter.com/iblamenfts"
                        className="twitter-color d-flex"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="twitter-icon d-flex mr-2"></span>
                        @IBLAMENFTS
                      </a>
                    </div> */}
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} className="order-md-1">
                  <div className="graphic-adjust pull-right">
                    <img
                      src={Team03}
                      alt="PETER PARKER"
                      className="img-fluid"
                    />
                    <div className="team-username">PETER PARKER</div>
                    <div className="team-controls">
                      <button
                        type="button"
                        className="btn-left"
                        onClick={() => this.changeSlide(-1)}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        className="btn-right"
                        onClick={() => this.changeSlide(1)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {this.state.showTeamMember === 4 && (
              <Row className="d-flex align-items-center justify-content-between even">
                <Col xs={12} md={6} lg={6} className="order-md-2">
                  <div className="section-adjust">
                    <h1 className="title">
                      <span>NEO</span>
                      {/* <a
                        href="https://www.paperhands.gg"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        PAPERHANDS.GG
                      </a> */}
                    </h1>
                    <p className="fs-24">
                      <span className="w-100 d-inline-block text-white">
                        ARTIST. GAME DESIGNER. NFT LOVER.
                      </span>{" "}
                      NEO IS ALL THINGS PIXEL. HE'S BEEN A COMMERCIAL PIXEL
                      ARTIST FOR THE PAST 8 YEARS WORKING ON EVERYTHING FROM WEB
                      ASSETS THROUGH TO COMMERCIAL GAMING PROJECTS (MOBILE
                      INCLUDED). YOU CAN TELL BY THE QUALITY OF HIS ARTWORK,
                      HE'S GOT AN AMAZING ATTENTION TO DETAIL AND EVERYTHING HE
                      CREATES IS DONE WITH PLENTY OF LOVE!
                    </p>
                    {/* <div className="fs-36">
                      <a
                        href="https://twitter.com/iblamenfts"
                        className="twitter-color d-flex"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="twitter-icon d-flex mr-2"></span>
                        @IBLAMENFTS
                      </a>
                    </div> */}
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} className="order-md-1">
                  <div className="graphic-adjust pull-right">
                    <img src={Team04} alt="NEO" className="img-fluid" />
                    <div className="team-username">NEO</div>
                    <div className="team-controls">
                      <button
                        type="button"
                        className="btn-left"
                        onClick={() => this.changeSlide(-1)}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        className="btn-right"
                        onClick={() => this.changeSlide(1)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {this.state.showTeamMember === 5 && (
              <Row className="d-flex align-items-center justify-content-between even">
                <Col xs={12} md={6} lg={6} className="order-md-2">
                  <div className="section-adjust">
                    <h1 className="title">
                      <span>BANKS</span>
                      {/* <a
                        href="https://www.paperhands.gg"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        PAPERHANDS.GG
                      </a> */}
                    </h1>
                    <p className="fs-24">
                      <span className="w-100 d-inline-block text-white">
                        NFT EXTRAORDINAIRE. LEGEND. ADVISOR.
                      </span>{" "}
                      WHILE THIS MAN NEEDS NO INTRODUCTION, WE'RE PLEASED TO
                      HAVE BANKS ON BOARD AS AN OFFICIAL ADVISOR. BUT, NOT ONLY
                      IS BANKS AN ADVISOR FOR ACROCALYPSE, THE RELATIONSHIP IS
                      BIDIRECTIONAL WITH ACROCALYPSE BEING THE OFFICIAL TECH
                      PARTNER FOR HIS UPCOMING WEB3 PROJECTS AND NEWLY FORMED
                      WEB3 STUDIO! BEING INVOLVED IN THIS JOURNEY WITH BANKS WE
                      AIM TO BRING AS MUCH BENEFIT BACK TO THE ACROCALYPSE
                      COMMUNITY AS POSSIBLE.
                    </p>
                    {/* <div className="fs-36">
                      <a
                        href="https://twitter.com/iblamenfts"
                        className="twitter-color d-flex"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="twitter-icon d-flex mr-2"></span>
                        @IBLAMENFTS
                      </a>
                    </div> */}
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} className="order-md-1">
                  <div className="graphic-adjust pull-right">
                    <img src={Team05} alt="BANKS" className="img-fluid" />
                    <div className="team-username">BANKS</div>
                    <div className="team-controls">
                      <button
                        type="button"
                        className="btn-left"
                        onClick={() => this.changeSlide(-1)}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        className="btn-right"
                        disabled={true}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </section>
      </>
    );
  }
}

export default SectionTeam;
