import { FunctionComponent, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Faq from "react-faq-component";

const data = {
  title: "",
  rows: [
    {
      title: "WHAT DOES MY CROC GET ME?",
      content: (
        <p>
          AN ACROCALYPSE NFT GETS YOU NEXT-GENERATION DIGITAL UTILITY, A PASS TO
          ALL THINGS ANNOUNCED IN ANY OF OUR ROADMAP RELEASES, ACCESS TO FUTURE
          MINTS, INVASIONS, LIFE LONG FRENSHIP WITH 10,420 CROCS AND OF COURSE A
          DOPE AF PFP.
        </p>
      ),
    },
    {
      title: "HOW DO I PURCHASE AN ACROCALYPSE NFT?",
      content: (
        <p>
          YOU'LL FIND US LISTED ON ALL NOTABLE NFT MARKETPLACES (E.G. OPENSEA),
          FOLLOW THE 'BUY ON OS' BUTTON TO GET THERE SAFELY!
        </p>
      ),
    },
    {
      title: "WHERE DID ACROCALYPSE COME FROM?",
      content: (
        <p>
          ACROCALYPSE WAS BORN AS THE BRAIN CHILD OF SAM WITWICKY, THE DEGEN
          FOUNDER BEHIND PAPERHANDS.GG! THIS FUN FREE APP IS LOVED BY ALL
          (SOMETIMES...) BUT WAS ONLY THE BEGINNING OF WHERE SAM WANTED TO TAKE
          THE PLATFORM AND HE'S BEEN BUILDING EVER SINCE.
        </p>
      ),
    },
    {
      title: "CAN I ASK MY OWN QUESTION?",
      content: <p>JOIN US IN THE DISCORD.</p>,
    },
  ],
};

const styles = {
  // bgColor: "white",
  // titleTextColor: "blue",
  // rowTitleColor: "blue",
  // rowContentColor: "grey",
  // arrowColor: "red",
};

const config = {
  animate: true,
  arrowIcon: " ",
  tabFocus: true,
};

interface Props {}

const SectionFAQs: FunctionComponent<Props> = () => {
  const [rows, setRowsOption] = useState<any[]>([]);

  useEffect(() => {
    if (rows && rows[0]) {
      // rows[0].expand();
    }
  }, [rows]);

  return (
    <section id="FAQ" className="section-faqs">
      <Container fluid={false}>
        <h1 className="title text-center mb-30">FAQ</h1>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={8} lg={8}>
            <Faq
              data={data}
              styles={styles}
              config={config}
              getRowOptions={setRowsOption}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionFAQs;
