import { useState } from "react";
import {
  Container,
  UncontrolledCollapse,
  Button,
  CardBody,
  Card,
} from "reactstrap";

// Static Assets
import ArrowRight from "../assets/images/icons/Arrow-Right.svg";
import TimeLine01 from "../assets/images/timeline-01.png";
import TimeLine02 from "../assets/images/timeline-02.png";
import TimeLine03 from "../assets/images/timeline-03.png";
import TimeLine04 from "../assets/images/timeline-04.png";
import TimeLine05 from "../assets/images/timeline-05.png";
import TimeLine06 from "../assets/images/timeline-06.png";
import TimeLine07 from "../assets/images/timeline-07.png";
// import TimeLine08 from "../assets/images/timeline-08.png";
import TimeLine08 from "../assets/images/timeline-08-animated.gif";
import TimeLine09 from "../assets/images/timeline-09.png";

import RoadmapIcon01 from "../assets/images/icons/iconroadmap-01@2x.png";
import RoadmapIcon02 from "../assets/images/icons/iconroadmap-02@2x.png";
import RoadmapIcon03 from "../assets/images/icons/iconroadmap-03@2x.png";
import RoadmapIcon04 from "../assets/images/icons/iconroadmap-04@2x.png";
import RoadmapIcon05 from "../assets/images/icons/iconroadmap-05@2x.png";
import RoadmapIcon06 from "../assets/images/icons/iconroadmap-06@2x.png";
import RoadmapIcon07 from "../assets/images/icons/iconroadmap-07@2x.png";

export default function SectionRoadmap() {
  const [isActiveRoadmap1, setIsActiveRoadmap1] = useState(false);
  const [isActiveRoadmap2, setIsActiveRoadmap2] = useState(true);
  // const handleClick = (event: {
  //   currentTarget: { classList: { toggle: (arg0: string) => void } };
  // }) => {
  //   // setIsActive((current) => !current)
  //   event.currentTarget.classList.toggle("is-active");
  // };

  return (
    <>
      <section id="Roadmap" className="section-roadmap pb-0">
        <Container fluid={false}>
          <div className="w-100 d-flex flex-column">
            <Button
              id="toggler"
              color="primary"
              className={
                isActiveRoadmap1 ? "button-title is-active" : "button-title"
              }
              onClick={() => setIsActiveRoadmap1(!isActiveRoadmap1)}
            >
              <span className="icon-20">
                <img src={ArrowRight} className="" alt="Arrow" />
              </span>
              <h1 className="title text-center m-0">ROADMAP 1.0</h1>
            </Button>
            <UncontrolledCollapse
              toggler="#toggler"
              isOpen={isActiveRoadmap1 ? true : false}
            >
              <Card>
                <CardBody>
                  <div className="roadmap-timeline">
                    <ul className="roadmap-timeline-lists">
                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-inner">
                            <label className="date first">
                              <span></span>COMPLETE
                            </label>
                            <h3 className="title">MID FEBRUARY</h3>
                            <p>
                              HODL EVENTS TOOL BETA LAUNCHED! ONE PLACE TO RULE
                              THEM ALL.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-inner">
                            <label className="date first">
                              <span></span>COMPLETE
                            </label>
                            <h3 className="title">MID FEBRUARY</h3>
                            <p>
                              Public floor tracking site online. a gateway for
                              onboarding new members TO ACROCALYPSE.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-inner">
                            <label className="date first">
                              <span></span>COMPLETE
                            </label>
                            <h3 className="title">Mid April</h3>
                            <p>
                              $PAPER TOKEN LAUNCHED. CLAIM NOW LIVE, STAKING Due
                              Mid May. UTILITY MADE CLEAR IN ROADMAP 2.0.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-inner">
                            <label className="date first">
                              <span></span>COMPLETE
                            </label>
                            <h3 className="title">April 29</h3>
                            <p>
                              A BRAND NEW 10K COLLECTION IS DROPPING ONLY FOR
                              CROC HOLDERS.
                            </p>
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-inner">
                            <label className="date first">
                              <span></span>COMPLETE
                            </label>
                            <h3 className="title">EARLY MAY</h3>
                            <p>
                              NFT PORTFOLIO MANAGEMENT BETA LAUNCHED. EXCLUSIVE
                              ACCESS TO CROC HODLERS! TRACK, VALUE, LIST...
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    {/* <div className="w-full d-flex justify-content-center">
                      <div className="roadmap-version d-flex justify-content-center align-items-center flex-column text-center fs-24">
                        <span className="text-white">ROADMAP VERSION 1.0</span>
                        <span className="w-full d-inline-block text-center">
                          UPDATED QUARTERLY
                        </span>
                      </div>
                    </div> */}
                  </div>
                </CardBody>
              </Card>
            </UncontrolledCollapse>

            <Button
              id="toggler2"
              color="primary"
              className={
                isActiveRoadmap2 ? "button-title is-active" : "button-title"
              }
              onClick={() => setIsActiveRoadmap2(!isActiveRoadmap2)}
            >
              <span className="icon-20">
                <img src={ArrowRight} className="" alt="Arrow" />
              </span>
              <h1 className="title text-center m-0">ROADMAP 2.0</h1>
            </Button>
            <UncontrolledCollapse
              toggler="#toggler2"
              isOpen={isActiveRoadmap2 ? true : false}
            >
              <Card>
                <CardBody>
                  <div className="roadmap-timeline2">
                    <ul className="roadmap-timeline-lists2">
                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-message">
                            <span className="icon-50px">
                              <img
                                src={RoadmapIcon01}
                                alt="RoadmapIcon01"
                                className="img-fluid"
                              />
                            </span>
                            PARTNER PROJECT WL
                          </div>
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine01}
                              alt="TimeLine01"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-message">
                            <span className="icon-50px">
                              <img
                                src={RoadmapIcon02}
                                alt="RoadmapIcon02"
                                className="img-fluid"
                              />
                            </span>
                            $PAPER MARKETPLACE
                          </div>
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine02}
                              alt="TimeLine02"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-message">
                            <span className="icon-50px">
                              <img
                                src={RoadmapIcon03}
                                alt="RoadmapIcon03"
                                className="img-fluid"
                              />
                            </span>
                            BLOCKCHAIN P2E GAME
                          </div>
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine03}
                              alt="TimeLine03"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-message">
                            <span className="icon-50px">
                              <img
                                src={RoadmapIcon04}
                                alt="RoadmapIcon04"
                                className="img-fluid"
                              />
                            </span>
                            $PAPER CASINO MINIGAMES
                          </div>
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine04}
                              alt="TimeLine04"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-message">
                            <span className="icon-50px">
                              <img
                                src={RoadmapIcon05}
                                alt="RoadmapIcon05"
                                className="img-fluid"
                              />
                            </span>
                            PORTFOLIO MANAGEMENT 2.0
                          </div>
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine05}
                              alt="TimeLine05"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-message">
                            <span className="icon-50px">
                              <img
                                src={RoadmapIcon06}
                                alt="RoadmapIcon06"
                                className="img-fluid"
                              />
                            </span>
                            NFT PROJECT ACTIVITY FEED
                          </div>
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine06}
                              alt="TimeLine06"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-message">
                            <span className="icon-50px">
                              <img
                                src={RoadmapIcon07}
                                alt="RoadmapIcon07"
                                className="img-fluid"
                              />
                            </span>
                            COMMUNITY LORE EXPANSION
                          </div>
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine07}
                              alt="TimeLine07"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine08}
                              alt="TimeLine08"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="roadmap-timeline-lists-items">
                        <div className="roadmap-timeline-wrap">
                          <div className="roadmap-timeline-inner">
                            <img
                              src={TimeLine09}
                              alt="TimeLine09"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div className="roadmap-timeline-colabs">
                      PLUS: COLLABS, PARTNERSHIPS AND MORE (THE FUTURE IS AGILE)
                    </div>
                  </div>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>
        </Container>
      </section>
    </>
  );
}
